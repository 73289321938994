import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dsfr d-flex flex-column",attrs:{"to":_vm.canteenLink,"outlined":"","ripple":false}},[_c(VImg,{attrs:{"src":_vm.canteenImage || '/static/images/canteen-default-image.jpg',"height":"160","max-height":"160"}}),_c(VCardTitle,[_c('h3',{staticClass:"fr-h6 font-weight-bold mb-1"},[_vm._v(_vm._s(_vm.canteen.name))])]),_c(VCardSubtitle,{staticClass:"pb-0 mx-n1"},[_c('p',{staticClass:"pl-1 mb-2"},[_vm._v("SIRET : "+_vm._s(_vm.canteen.siret || "inconnu"))]),(_vm.canteen.sirenUniteLegale)?_c('p',{staticClass:"pl-1 mb-2"},[_vm._v("SIREN de l'unité légale : "+_vm._s(_vm.canteen.sirenUniteLegale))]):_vm._e(),(_vm.teledeclarationIsActive && !_vm.usesCentralKitchenDiagnostics)?_c('DsfrTag',{attrs:{"text":_vm.teledeclarationStatus.text,"color":_vm.teledeclarationStatus.color,"small":true}}):_vm._e(),(_vm.publicationStatusIsNotPublished)?_c('DsfrTag',{attrs:{"text":_vm.publicationStatus.text,"color":_vm.publicationStatus.color,"small":true}}):_vm._e()],1),_c(VCardSubtitle,{staticClass:"mt-0 pb-0 pt-2"},[_c('ProductionTypeTag',{attrs:{"canteen":_vm.canteen,"position":"top-left"}}),_c('CanteenIndicators',{attrs:{"canteen":_vm.canteen}})],1),_c(VSpacer),_c(VCardActions,{staticClass:"px-4 py-4"},[_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("$arrow-right-line")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }